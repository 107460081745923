<template>
  <div class="premium-menu-show-additional-information">
    <v-radio-group :value="value" row @change="handleChange">
      <v-radio label="Activado" :value="true"></v-radio>
      <v-radio label="Desactivado" :value="false"></v-radio>
    </v-radio-group>
  </div>
</template>

<script>
export default {
  name: 'PremiumMenuShowAdditionalInformation',
  model: {
    prop: 'value',
    event: 'change'
  },
  props: {
    value: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    /**
     * Manejador del cambio de valor
     *
     * @param {object} event
     */
    handleChange(event) {
      this.$emit('change', event)
    }
  }
}
</script>
