// Constants
import { ADDONS } from '@/constants'
// Components
import AddonsNeedUpgradeContent from '@/components/elements/addons/AddonsNeedUpgradeContent'
import CardContainer from '@/components/ui/CardContainer'
import CardContainerElement from '@/components/ui/CardContainerElement'
import FormButtons from '@/components/ui/FormButtons'
import PremiumMenuAdditionalInformationType from '@/addons/premiumMenu/components/elements/PremiumMenuAdditionalInformationType'
import PremiumMenuShowAdditionalInformation from '@/addons/premiumMenu/components/elements/PremiumMenuShowAdditionalInformation'
import TrackerCovidSwitch from '@/addons/trackerCovid/components/elements/TrackerCovidSwitch'
import VuetifyToolBar from '@/components/ui/VuetifyToolBar'
// Mixins
import addonsMixin from '@/mixins/addonsMixin'
import formMixin from '@/mixins/formMixin'
import uiMixin from '@/mixins/uiMixin'
// Vuelidate plugin
import { validationMixin } from 'vuelidate'
// Vuex
import { mapGetters, mapActions } from 'vuex'
// Utils
import { get } from 'lodash'
// Filters
import { sanitizeHtmlContent } from '@/filters'

export default {
  name: 'ConfigMiscellaneous',
  components: {
    AddonsNeedUpgradeContent,
    CardContainer,
    CardContainerElement,
    FormButtons,
    PremiumMenuAdditionalInformationType,
    PremiumMenuShowAdditionalInformation,
    TrackerCovidSwitch,
    VuetifyToolBar
  },
  mixins: [addonsMixin, formMixin, uiMixin, validationMixin],
  data() {
    return {
      // Form
      formFields: {}
    }
  },
  computed: {
    ...mapGetters('place', ['placeData']),
    /**
     * ¿Desea mostrar mensaje de información?
     *
     * @return {Boolean}
     */
    isShowAdditionalInformation() {
      return this.formFields.showAdditionalInformation
    }
  },
  async mounted() {
    await this.getEveryNeededData()
  },
  methods: {
    ...mapActions('place', ['updateConfigs']),
    /**
     * Obtenemos y seteamos todos los elementos necesarios
     * para la carga inicial del componente
     */
    async getEveryNeededData() {
      this.setFormFieldsValues()
    },
    /**
     * Set inital values in formulary field to set the validation correctly
     */
    setFormFieldsValues() {
      // TrackerCovid AddOn
      if (this.hasTrackerCovidAddon) {
        const trackerCovidConfig = get(this.placeAddonsSetupByUser, ADDONS.trackerCovid, {})
        // Creamos nuevo campo
        this.$set(this.formFields, 'enabled', trackerCovidConfig.enabled || false)
      }
      // AddOn "premimMenu"
      if (this.hasPremiumMenuAddon) {
        const premiumMenuConfig = get(this.placeAddonsSetupByUser, ADDONS.premiumMenu, {})

        this.$set(
          this.formFields,
          'additionalInformation',
          sanitizeHtmlContent(premiumMenuConfig.additionalInformation || null, [])
        )
        this.$set(
          this.formFields,
          'additionalInformationType',
          premiumMenuConfig.additionalInformationType || 'info'
        )
        this.$set(
          this.formFields,
          'showAdditionalInformation',
          premiumMenuConfig.showAdditionalInformation || false
        )
      }
    },
    /**
     * Is triggering after the form is correctly
     * validated by "Vuelidate"
     */
    async afterSubmit() {
      const addOns = []

      // TrackerCovid AddOn
      if (this.hasTrackerCovidAddon) {
        addOns.push({
          id: ADDONS.trackerCovid,
          configFields: {
            enabled: this.formFields.enabled
          }
        })
      }

      // Datos a almacenar en "premiumMenu"
      if (this.hasPremiumMenuAddon) {
        addOns.push({
          id: ADDONS.premiumMenu,
          configFields: {
            additionalInformation: sanitizeHtmlContent(this.formFields.additionalInformation, []),
            additionalInformationType: this.formFields.additionalInformationType,
            showAdditionalInformation: this.formFields.showAdditionalInformation
          }
        })
      }

      // Actualizamos configuraciones
      await this.updateConfigs({
        id: this.placeData.id,
        addOns
      })

      // show info
      this.modifyAppAlert({
        text: 'Los cambios se guardaron correctamente',
        visible: true
      })
    }
  },
  validations() {
    const rules = {
      formFields: {}
    }

    // TrackerCovid AddOn
    if (this.hasTrackerCovidAddon) {
      rules.formFields.enabled = {}
    }

    // Tenemos "premiumMenu"
    if (this.hasPremiumMenuAddon) {
      rules.formFields.additionalInformation = {}
      rules.formFields.additionalInformationType = {}
      rules.formFields.showAdditionalInformation = {}
    }

    return rules
  }
}
