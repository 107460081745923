<template>
  <div class="tracker-covid-switch">
    <v-radio-group :value="value" row @change="handleChangeSwitch">
      <v-radio label="Activado" :value="true"></v-radio>
      <v-radio label="Desactivado" :value="false"></v-radio>
    </v-radio-group>
  </div>
</template>

<script>
export default {
  name: 'TrackerCovidSwitch',
  model: {
    prop: 'value',
    event: 'change'
  },
  props: {
    value: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    /**
     * Manejador del cambio de valor
     *
     * @param {Object} event
     */
    handleChangeSwitch(event) {
      this.$emit('change', event)
    }
  }
}
</script>
