<template>
  <div class="premium-menu-additional-information-type">
    <v-select
      v-if="visible"
      :value="value"
      v-bind="selectVOptions"
      :items="additionalInformationTypes"
      item-value="value"
      item-text="label"
      @change="handleChange"
    />
  </div>
</template>

<script>
export default {
  name: 'PremiumMenuAdditionalInformationType',
  model: {
    prop: 'value',
    event: 'change'
  },
  props: {
    value: {
      type: String,
      default: 'info'
    },
    visible: {
      type: Boolean,
      default: true
    },
    selectVOptions: {
      type: Object,
      default() {
        return {
          label: 'Tipo de mensaje',
          outlined: true,
          dense: true,
          color: 'grey'
        }
      }
    }
  },
  data() {
    return {
      additionalInformationTypes: [
        {
          value: 'info',
          label: 'Información'
        },
        {
          value: 'warning',
          label: 'Advertencia'
        },
        {
          value: 'success',
          label: 'Afirmación'
        },
        {
          value: 'error',
          label: 'Precaución'
        }
      ]
    }
  },
  methods: {
    /**
     * Evento lanzado tras la modificación del selector
     *
     * @param {String} event
     */
    handleChange(event) {
      this.$emit('change', event)
    }
  }
}
</script>
